<template>
  <div
    v-if="loaded"
    :class="{ mobile: isMobile }"
    :style="{
      '--max-width': windowWidth + 'px',
      overflowX: 'hidden',
      overflowY: 'hidden'
    }"
  >
    <section name="cover" :style="$background(bgCover)" v-if="!closeCover">
      <CoverSection @open="openInvitation" :sections="$sections.cover"/>
    </section>

    <template v-if="opened">
      <section name="content" :style="{background: bgContent}">
        <!-- start::sections -->
        <OpeningSection data-aos="fade-up"/>
        <TimeSection data-aos="fade-up"/>
        <LocationSection/>
        <RSVPSection v-if="optional_sections.rsvp && showRsvp"/>
        <StorySection v-if="optional_sections.story" data-aos="fade-up"/>
        <GallerySection v-if="optional_sections.gallery" data-aos="fade-up"/>
        <VideoSection v-if="optional_sections.video" data-aos="fade-up"/>
        <StreamingSection v-if="optional_sections.streaming" data-aos="fade-up"/>
        <WishSection data-aos="fade-up" :wishes="wishesData" @update="getWishes"/>
        <GiftSection v-if="optional_sections.gift" data-aos="fade-up"/>
        <WarningSection v-if="optional_sections.warning" data-aos="fade-up"/>
        <NotesSection v-if="optional_sections.notes" data-aos="fade-up"/>
        <FooterSection data-aos="fade-up"/>
        <!-- end::sections -->

        <!-- start::resources -->
        <FloatingMenu v-if="isMobile" data-aos="zoom-in" :showRsvp="showRsvp" />
        <MusicButton data-aos="fade-left"/>
        <!-- end::resources -->
      </section>
    </template>
  </div>
  <MomentoLoading v-else/>
</template>

<script>
import Vue from "vue"

// start::Sections
import CoverSection from "@/components/sections/cover/CoverSection"
import OpeningSection from "@/components/sections/opening/OpeningSection"
import TimeSection from "@/components/sections/time/TimeSection"
import LocationSection from "@/components/sections/location/LocationSection"
import RSVPSection from "@/components/sections/rsvp/RSVPSection"
import StorySection from "@/components/sections/story/StorySection"
import GallerySection from "@/components/sections/gallery/GallerySection"
import VideoSection from "@/components/sections/video/VideoSection"
import StreamingSection from "@/components/sections/streaming/StreamingSection"
import WishSection from "@/components/sections/wish/WishSection"
import GiftSection from "@/components/sections/gift/GiftSection"
import WarningSection from "@/components/sections/warning/WarningSection"
import NotesSection from "@/components/sections/notes/NotesSection"
import FooterSection from "@/components/sections/footer/FooterSection"
// end::Sections

export default {
  components: { CoverSection, OpeningSection, TimeSection, LocationSection, RSVPSection, StorySection, GallerySection, VideoSection, StreamingSection, WishSection, GiftSection, WarningSection, NotesSection, FooterSection },
  data() {
    return {
      opened: false,
      loaded: false,
      iconDir: "default",
      optional_sections: {},
      closeCover: false,
      wishesData: [],
      showRsvp: false,
    }
  },
  computed: {
    bgCover() {
      const { iconDir, device } = this
      const { background } = this.$baseComponents

      if(background.withImage) return `url('/bg-icons/${iconDir}/background-cover-${device}.png')`

      return background.bgColor
    },
    bgContent() {
      const { iconDir, device } = this
      const { background } = this.$baseComponents

      if(background.withImage) return `url('/bg-icons/${iconDir}/background-${device}.png')`

      return background.bgColor
    },
    username() {
      return this.$route.params.username
    }
  },
  methods: {
    __init() {
      Promise.all([
        this.getWeddingData(),
        this.getOptionalSections(),
        this.getGuest(),
        this.getWishes()
      ]).then(() => this.loaded = true)
    },
    async getWeddingData() {
      const res = await this.$settingRepository.getWeddingData()

      if(res.success) {
        Vue.prototype.$weddingData = { ...this.$weddingData, ...res.data}

        this.setTheme()
      }
    },
    async getOptionalSections() {
      const res = await this.$settingRepository.getOptionalSections()

      if(res.success) {
        this.optional_sections = res.data

        Vue.prototype.$optionalSections = res.data
      }
    },
    async getGuest() {
      const res = await this.$guestRepository.getGuestByUsername(this.username)

      if(res.success) {
        this.showRsvp = true

        const rsvp = await this.getRsvp()

        Vue.prototype.$guestData = { ...res.data.data(), rsvp }
      }

    },
    async getRsvp() {
      const res = await this.$rsvpRepository.getRsvpByUsername(this.username)

      if(res.success) return {
        id: res.data.id,
        ...res.data.data()
      }
    },
    async getWishes() {
      const res = await this.$wishRepository.getWishes()

      this.wishesData = []

      if(res.success) {
        res.data.docs.forEach(wish => {
          this.wishesData.push({
            id: wish.id,
            name: wish.data().name,
            comment: wish.data().message
          })
        })

        this.wishesData.sort((a, b) => b.id - a.id)
      }
    },
    setTheme() {
      const { baseComponents, sections, decorations } = require(`@/assets/themes/${this.$weddingData.theme}`)

      Vue.prototype.$baseComponents = baseComponents
      Vue.prototype.$decorations = decorations
      Vue.prototype.$sections = { ...this.$sections, ...sections }

      this.iconDir = this.$baseComponents.icon.dir
    },
    openInvitation() {
      this.opened = true

      setTimeout(() => {
        this.closeCover = true
        window.scrollTo({ top: top })
      }, 1000)

      setTimeout(() => {
        const element = document.getElementById("opening-section")
        const top = element.offsetTop
  
        window.scrollTo({ top: top, behavior: 'smooth' })
      }, 100)
    }
  },
  mounted() {
    this.__init()
  }
}
</script>

<style scoped>
  section[name=cover], section[name=content] {
    background-size: cover;
    background-position: 0 0;
    background-repeat: no-repeat;
  }
</style>

<style mobile scoped>
  .mobile {
   width: var(--max-width);
   max-width: var(--max-width);
  }
</style>