<template>
  <div
    id="location-section"
    :class="{
      'd-flex align-items-center justify-content-center mt-12-5': isDesktop,
      'px-3-752 mt-5': isMobile
    }"
    class="position-relative"
    :style="styles"
  >
    <div name="content" data-aos="fade-up" class="position-relative z-index-2">
      <template v-if="isDesktop">
        <ColorWrapper
          class="d-flex justify-content-center w-100 maxh-35-46 maxh-33-28 mb-16-752 overflow-hidden"
          :style="{
            paddingTop: css.wrapperPaddingTop
          }"
          :noBorder="css.noBorder"
        >
          <div class="text-center w-100">
            
            <section
              name="header" 
              :style="{
                  borderWidth: css.headerBorderWidth,
                  borderStyle: css.headerBorderStyle,
                  borderColor: css.headerBorderColor,
                  borderRadius: css.headerBorderRadius
                }"
              :class="{
                'pt-5': css.noBorder 
              }"
            >
              <section name="icon">
                <img :width="css.iconWidth" :src="`/icons/${iconDir}/location.svg`">
              </section>

              <section
                name="title"
                class="pb-5"
              >
                <p class="title">{{ $weddingData.locationPlace }}</p>
                <p 
                  class="desc mt-1 m-auto"
                  :style="{
                    maxWidth: css.addressMaxWidth
                  }"
                >
                  {{ $weddingData.locationAddress }}
                </p>
              </section>
            </section>

            <section name="maps" class="d-flex">
              <iframe
                :src="$weddingData.locationMapsUrl"
                class="g-maps w-rem-75 h-rem-24-813"
                style="border: 0;"
                allowfullscreen="true"
                loading="lazy"
              ></iframe>
            </section>
          </div>
        </ColorWrapper>
      </template>

      <template v-else>
        <section name="maps">
          <iframe
            :src="$weddingData.locationMapsUrl"
            class="g-maps w-100 h-rem-21-875"
            style="border: 0;"
            allowfullscreen="true"
            loading="lazy"
          ></iframe>
        </section>

        <ColorWrapper class="d-flex justify-content-center pt-5 mt-5 overflow-hidden mx-3-752">
          <div class="text-center w-100">
            <section name="icon">
              <img :width="css.m_iconWidth" :src="`/icons/${iconDir}/location.svg`">
            </section>

            <section
              name="title"
              class="pb-5"
            >
              <p class="title">{{ $weddingData.locationPlace }}</p>
              <p class="desc px-5 m-auto">{{ $weddingData.locationAddress }}</p>
            </section>
          </div>
        </ColorWrapper>

        <Link :to="$weddingData.locationShare" external newtab>
          <section name="open-maps" class="mt-5 d-flex justify-content-center">
            <Button icon="location-now.svg" text="Lihat Arah Lokasi" />
          </section>
        </Link>
      </template>
    </div>

    <div name="decorations">
      <LocationDecoration />
    </div>
  </div>
</template>

<script>
import LocationDecoration from "@/components/sections/location/LocationDecoration"

export default {
  components: { LocationDecoration },
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      css: {
        titleFontFamily: "opensansB",
        titleFontSize: "1.25rem",
        titleColor: "#161616",
        descFontFamily: "opensansR",
        descFontSize: "1rem",
        descColor: "#161616",
        wrapperPaddingTop: "1.25rem",
        addressMaxWidth: "40%",

        ...this.$sections.location
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--title-font-family': css.titleFontFamily,
        '--title-font-size': css.titleFontSize,
        '--title-color': css.titleColor,
        '--desc-font-family': css.descFontFamily,
        '--desc-font-size': css.descFontSize,
        '--desc-color': css.descColor
      }

      const mobile = {
        '--m-title-font-size': css.m_titleFontSize,
        '--m-desc-font-size': css.m_descFontSize
      }

      return { ...desktop, ...mobile }
    }
  }
}
</script>

<style scoped>
  section[name=title] p.title {
    font-family: var(--title-font-family);
    font-size: var(--title-font-size);
    color: var(--title-color);
  }

  section[name=title] p.desc {
    font-family: var(--desc-font-family);
    font-size: var(--desc-font-size);
    color: var(--desc-color);
  }

  section[name=maps] iframe {
    border-radius: 0 0 1.25rem 1.25rem;
  }
</style>

<style mobile scoped>
  .mobile section[name=title] p.title {
    font-size: var(--m-title-font-size);
  }

  .mobile section[name=title] p.desc {
    font-size: var(--m-desc-font-size);
  }

  .mobile section[name=maps] iframe {
    border-radius: 0.625rem;
  }

  .mobile section[name=open-maps] {
    margin-left: 1.563rem !important;
    margin-right: 1.563rem !important;
  }
</style>