<template>
  <div
    id="gallery-section"
    class=""
    :class="{
      'd-flex align-items-center justify-content-center mt-17-5': isDesktop,
      'mt-11': isMobile && !$optionalSections.story
    }"
  >
    <div name="content" class="position-relative z-index-2">
      <section name="initial" class="text-center">
        <img :width="isDesktop ? css.galleryInitialWidth : css.m_galleryInitialWidth" :src="$weddingData.galleryInitialSrc">
      </section>

      <section name="images" class="mt-7-5 text-center">
        <component
          :is="$weddingData.galleryType"
          :portraits="$weddingData.galleryPortraits"
          :landscapes="$weddingData.galleryLandscapes"
        />
      </section>
    </div>

    <div name="decorations">
      <GalleryDecoration />
    </div>
  </div>
</template>

<script>
import GalleryDecoration from "@/components/sections/gallery/GalleryDecoration"

import p2l3 from "@/components/sections/gallery/p2l3"
import p3l2 from "@/components/sections/gallery/p3l2"
import p5l0 from "@/components/sections/gallery/p5l0"

export default {
  components: { GalleryDecoration, p2l3, p3l2, p5l0 },
  data() {
    return {
      css: {
        ...this.$sections.gallery
      }
    }
  }
}
</script>

<style>
.frame-container {
  height: 28.125rem;
}

.frame-container img:hover {
  cursor: pointer;
}
</style>

<style mobile scoped>
  .mobile .frame-container {
    height: unset !important;
  }
</style>