<template>
  <div id="gallery-decoration" :style="styles">
    <div name="bottom-center" v-if="decor.bottomCenter">
      <img :src="`${iconDir}/bottom-center.png`" class="decoration" alt="bottom-center-decoration" data-aos="fade-top">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    decor() {
      let decors = this.$decorations.gallery

      if(this.isMobile) decors = this.$checkMobileDecoration(decors)
      
      return decors
    },
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/gallery/${this.device}`
    },
    styles() {
      const css = this.$decorations.gallery

      const mobile = {
        '--m-bottom-center-bottom': css.m_bottomCenter_bottom,
        '--m-bottom-center-width': css.m_bottomCenter_width,
        '--m-bottom-center-z-index': css.m_bottomCenter_zIndex
      }

      return mobile
    }
  }
}
</script>

<style mobile scoped>
  .mobile [name=bottom-center] img {
    bottom: var(--m-bottom-center-bottom);
    width: var(--m-bottom-center-width);
    z-index: var(--m-bottom-center-z-index);
    margin: auto;
  }
</style>