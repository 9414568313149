var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center position-relative",style:(Object.assign({}, {minHeight: _vm.css.windowHeight + _vm.css.unit}, _vm.styles)),attrs:{"id":"cover-section"}},[_c('div',{attrs:{"name":"content","data-aos":"zoom-in"}},[_c('section',{staticClass:"d-flex justify-content-center",attrs:{"name":"bride-groom"}},[_c('div',{style:({
          width: _vm.css.nameWidth,
          marginBottom: _vm.$guestData ? _vm.css.marginBottom : (_vm.publicNameMarginBottom || _vm.css.marginBottom)
        }),attrs:{"name":"name"}},[_c('p',{staticClass:"w-max",style:({
            paddingLeft: _vm.isDesktop ? _vm.css.bridePaddingLeft : _vm.css.m_bridePaddingLeft,
            paddingRight: _vm.isDesktop ? _vm.css.bridePaddingRight : _vm.css.m_bridePaddingRight,
            marginLeft: _vm.isDesktop ? _vm.css.brideMarginLeft : _vm.css.m_brideMarginLeft,
            marginRight: _vm.isDesktop ? _vm.css.brideMarginRight : _vm.css.m_brideMarginRight
          }),attrs:{"name":"bride"}},[_vm._v(" "+_vm._s(_vm.$weddingData.brideNickName)+" ")]),_c('p',{staticClass:"text-center",style:({
            marginTop: _vm.isDesktop ? _vm.css.andMarginTop : _vm.css.m_andMarginTop,
            marginBottom: _vm.isDesktop ? _vm.css.andMarginBottom : _vm.css.m_andMarginBottom, 
            marginLeft: _vm.isDesktop ? _vm.css.andMarginLeft : _vm.css.m_andMarginLeft, 
            marginRight: _vm.isDesktop ? _vm.css.andMarginRight : _vm.css.m_andMarginRight
          }),attrs:{"name":"and"}},[_vm._v(" & ")]),_c('p',{staticClass:"w-max ml-auto",style:({
            paddingRight: _vm.isDesktop ? _vm.css.groomPaddingRight : _vm.css.m_groomPaddingRight, 
            paddingLeft: _vm.isDesktop ? _vm.css.groomPaddingLeft : _vm.css.m_groomPaddingLeft,
            marginLeft: _vm.isDesktop ? _vm.css.groomMarginLeft : _vm.css.m_groomMarginLeft,
            marginRight: _vm.isDesktop ? _vm.css.groomMarginRight : _vm.css.m_groomMarginRight
          }),attrs:{"name":"groom"}},[_vm._v(" "+_vm._s(_vm.$weddingData.groomNickName)+" ")])])]),(_vm.$guestData)?_c('section',{staticClass:"mt-15-252",attrs:{"name":"dear"}},[_c('p',{staticClass:"text-center",attrs:{"name":"guest-name"}},[_vm._v("Kepada Yth: "+_vm._s(_vm.$guestData.name))])]):_vm._e(),_c('section',{staticClass:"d-flex justify-content-center",style:({ marginTop: _vm.css.openBtnMarginTop }),attrs:{"name":"open"}},[_c('Button',{attrs:{"icon":"email-closed.svg","text":"Buka Undangan"},nativeOn:{"click":function($event){_vm.$buttonClick(function () { return _vm.$emit('open'); })}}})],1)]),_c('div',{attrs:{"name":"decorations"}},[_c('CoverDecoration')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }