var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex align-items-center justify-content-center mt-18",attrs:{"id":"gift-section"}},[_c('div',{attrs:{"name":"content"}},[_c('section',{attrs:{"name":"title"}},[_c('Title',{attrs:{"text":"Kirim Hadiah"}})],1),_c('section',{staticClass:"mt-5-752",attrs:{"name":"gift"}},[_c('ColorWrapper',{staticClass:"maxw-275",class:{
          'px-37-5 pt-10 pb-8-752': _vm.isDesktop,
          'px-8 mx-3-752 pt-6 pb-5': _vm.isMobile
        },attrs:{"backgroundColor":_vm.css.bgColor}},[_c('div',{staticClass:"d-flex justify-content-around",attrs:{"name":"payments"}},[_vm._l((_vm.$weddingData.payments),function(payment,index){return [_c('PaymentCard',_vm._b({key:index,class:{
                'mr-5': _vm.$weddingData.payments.length > 1 && (index + 1) != _vm.payments.length
              }},'PaymentCard',payment,false))]})],2),_c('p',{staticClass:"details text-center mt-7-5",style:({
            '--gift-detail-font-family': _vm.css.giftDetailFontFamily,
            '--gift-detail-font-size': _vm.css.giftDetailFontSize,
            '--gift-detail-font-color': _vm.css.giftDetailFontColor,
          })},[_vm._v("Terimakasih banyak atas hadiah yang dikirimkan kepada kami")])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }