<template>
  <div class="payment-card-container d-flex" @click="copyText">
    <div name="icon">
      <img :src="icon" :width="paymentIconWidth">
    </div>
    <div 
      name="detail" 
      class="ml-3-752"
      :style="styles"
    >
      <p class="code d-flex align-items-center">
        {{ code }}
        <span class="d-flex ml-1"><img name="copy-icon" :src="copyIcon" width="19"></span>
      </p>
      <p class="detail">{{ detail }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["icon", "code", "detail"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir || "default",
      isCopied: false,
      css: {
        codeFontFamily: "poppinsSB",
        codeFontSize: "1.25rem",
        codeFontColor: "#161616",
        detailFontFamily: "poppinsR",
        detailFontSize: "0.875rem",
        detailFontColor: "#161616",

        ...this.$sections.gift
      }
    }
  },
  computed: {
    paymentIconWidth() {
      return this.isDesktop ? 139 : 83
    },
    copyIcon() {
      const { isCopied, iconDir } = this
      const icon = isCopied ? "copied.png" : "copy.svg"

      return `/icons/${iconDir}/${icon}`
    },
    styles() {
      const { css } = this
      
      const desktop = {
        '--code-font-family': css.codeFontFamily,
        '--code-font-size': css.codeFontSize,
        '--code-font-color': css.codeFontColor,
        '--detail-font-family': css.detailFontFamily,
        '--detail-font-size': css.detailFontSize,
        '--detail-font-color': css.detailFontColor,
      }

      const mobile = {
        '--m-code-font-size': css.m_codeFontSize,
        '--m-detail-font-size': css.m_detailFontSize
      }

      return { ...desktop, ...mobile }
    }
  },
  methods: {
    copyText() {
      this.isCopied = true
      
      navigator.clipboard.writeText(this.code)
      
      setTimeout(() => this.isCopied = false, 1500)
    }
  }
}
</script>

<style scoped>
  p.code {
    font-family: var(--code-font-family);
    font-size: var(--code-font-size);
    color: var(--code-font-color);
  }

  p.detail {
    font-family: var(--detail-font-family);
    font-size: var(--detail-font-size);
    color: var(--detail-font-color);
  }
  
  [name=copy-icon]:hover {
    cursor: pointer;
  }
</style>

<style mobile scoped>
  .mobile p.code {
    font-size: var(--m-code-font-size);
  }

  .mobile p.detail {
    font-size: var(--m-detail-font-size);
  }
</style>