var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative",class:{
    'd-flex align-items-center justify-content-center mt-12-5': _vm.isDesktop,
    'px-3-752 mt-5': _vm.isMobile
  },style:(_vm.styles),attrs:{"id":"location-section"}},[_c('div',{staticClass:"position-relative z-index-2",attrs:{"name":"content","data-aos":"fade-up"}},[(_vm.isDesktop)?[_c('ColorWrapper',{staticClass:"d-flex justify-content-center w-100 maxh-35-46 maxh-33-28 mb-16-752 overflow-hidden",style:({
          paddingTop: _vm.css.wrapperPaddingTop
        }),attrs:{"noBorder":_vm.css.noBorder}},[_c('div',{staticClass:"text-center w-100"},[_c('section',{class:{
              'pt-5': _vm.css.noBorder 
            },style:({
                borderWidth: _vm.css.headerBorderWidth,
                borderStyle: _vm.css.headerBorderStyle,
                borderColor: _vm.css.headerBorderColor,
                borderRadius: _vm.css.headerBorderRadius
              }),attrs:{"name":"header"}},[_c('section',{attrs:{"name":"icon"}},[_c('img',{attrs:{"width":_vm.css.iconWidth,"src":("/icons/" + _vm.iconDir + "/location.svg")}})]),_c('section',{staticClass:"pb-5",attrs:{"name":"title"}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$weddingData.locationPlace))]),_c('p',{staticClass:"desc mt-1 m-auto",style:({
                  maxWidth: _vm.css.addressMaxWidth
                })},[_vm._v(" "+_vm._s(_vm.$weddingData.locationAddress)+" ")])])]),_c('section',{staticClass:"d-flex",attrs:{"name":"maps"}},[_c('iframe',{staticClass:"g-maps w-rem-75 h-rem-24-813",staticStyle:{"border":"0"},attrs:{"src":_vm.$weddingData.locationMapsUrl,"allowfullscreen":"true","loading":"lazy"}})])])])]:[_c('section',{attrs:{"name":"maps"}},[_c('iframe',{staticClass:"g-maps w-100 h-rem-21-875",staticStyle:{"border":"0"},attrs:{"src":_vm.$weddingData.locationMapsUrl,"allowfullscreen":"true","loading":"lazy"}})]),_c('ColorWrapper',{staticClass:"d-flex justify-content-center pt-5 mt-5 overflow-hidden mx-3-752"},[_c('div',{staticClass:"text-center w-100"},[_c('section',{attrs:{"name":"icon"}},[_c('img',{attrs:{"width":_vm.css.m_iconWidth,"src":("/icons/" + _vm.iconDir + "/location.svg")}})]),_c('section',{staticClass:"pb-5",attrs:{"name":"title"}},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$weddingData.locationPlace))]),_c('p',{staticClass:"desc px-5 m-auto"},[_vm._v(_vm._s(_vm.$weddingData.locationAddress))])])])]),_c('Link',{attrs:{"to":_vm.$weddingData.locationShare,"external":"","newtab":""}},[_c('section',{staticClass:"mt-5 d-flex justify-content-center",attrs:{"name":"open-maps"}},[_c('Button',{attrs:{"icon":"location-now.svg","text":"Lihat Arah Lokasi"}})],1)])]],2),_c('div',{attrs:{"name":"decorations"}},[_c('LocationDecoration')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }