<template>
  <div class="frame-container p5l0" v-viewer>
    <section
      name="p5l0"
      :class="{
        'w-100 h-100 d-flex': isDesktop,
      }"  
    >
      <template v-if="isDesktop">
        <div class="image-container" style="width: 313px">
          <img width="100%" height="450" :src="portraits[0]">
        </div>
        <div class="image-container mx-1-752" style="width: 155px">
          <img width="100%" height="222" :src="portraits[1]">
          <img width="100%" height="222" :src="portraits[2]">
        </div>
        <div class="image-container" style="width: 313px">
          <img width="100%" height="450" :src="portraits[3]">
        </div>
        <div class="image-container ml-1-752" style="width: 313px">
          <img width="100%" height="450" :src="portraits[4]">
        </div>
      </template>

      <template v-else>
        <div name="image-wrapper">
          <div class="image-container d-flex">
            <img width="50%" :src="portraits[2]">
            <img width="50%" :src="portraits[3]" style="margin-left: 0.375rem">
          </div>

          <div class="image-container" style="margin-top: 0.313rem">
            <img width="100%" :src="portraits[0]">
          </div>

          <div class="image-container d-flex">
            <img width="50%" :src="portraits[4]">
            <img width="50%" :src="portraits[1]" style="margin-left: 0.375rem">
          </div>
        </div>
      </template>
    </section>
  </div>
</template>

<script>
export default {
  props: ["portraits", "landscapes"]
}
</script>

<style scoped>
  .mobile .image-container {
    padding-left: 0.938rem;
    padding-right: 0.938rem;
  }
</style>