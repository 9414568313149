<template>
  <ColorWrapper
    class="d-flex justify-content-center w-100 maxw-200 py-5"
    :style="styles"
  >
    <div class="text-center w-100">
      <section name="icon">
        <img :width="isDesktop ? css.dateTimeIconSize : css.m_dateTimeIconSize" :src="`/icons/${iconDir}/time.svg`">
      </section>

      <template v-if="cardType == 1">
        <section name="detail" class="d-flex justify-content-evenly mt-7-5">
          <div class="detail-1">
            <p class="title">Akad Nikah</p>
            <p class="desc">{{ $weddingData.akadTime }}</p>
          </div>
          <div class="detail-2">
            <p class="title">Resepsi</p>
            <p class="desc">{{ $weddingData.receptionTime }}</p>
          </div>
        </section>
      </template>

      <template v-else-if="cardType == 2">
        <section name="detail" class="mt-7-5">
          <p class="title">Resepsi</p>
          <p class="desc">{{ $weddingData.receptionTime }}</p>
        </section>
      </template>

      <template v-else-if="cardType == 3">
        <section name="detail" class="mt-7-5">
          <p class="title">Waktu Akad</p>
          <p class="desc">{{ $weddingData.akadTime }}</p>
        </section>
      </template>

      <template v-else>
        <section name="detail" class="d-flex justify-content-evenly mt-7-5">
          <div class="detail-1">
            <p class="title">Akad Nikah</p>
            <p class="desc">{{ $weddingData.akadTime }}</p>
          </div>
          <div class="detail-2">
            <p class="title">Resepsi Sesi 1</p>
            <p class="desc">{{ $weddingData.receptionTime }}</p>
          </div>
          <div class="detail-3">
            <p class="title">Resepsi Sesi 2</p>
            <p class="desc">{{ $weddingData.reception2Time }}</p>
          </div>
        </section>
      </template>
    </div>
  </ColorWrapper>
</template>

<script>
export default {
  props: ["type"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      css: {
        scheduleTitleFF: "opensansB",
        scheduleTitleFS: "1.25rem",
        scheduleTitleC: "#161616",
        scheduleDescFF: "opensansR",
        scheduleDescFS: "1rem",
        scheduleDescC: "#161616",

        ...this.$sections.time
      }
    }
  },
  computed: {
    styles() {
      const { css } = this

      const desktop = {
        '--schedule-title-ff': css.scheduleTitleFF,
        '--schedule-title-fs': css.scheduleTitleFS,
        '--schedule-title-c': css.scheduleTitleC,
        '--schedule-desc-ff': css.scheduleDescFF,
        '--schedule-desc-fs': css.scheduleDescFS,
        '--schedule-desc-c': css.scheduleDescC,
      }

      const mobile = {
        '--m-schedule-title-fs': css.m_scheduleTitleFS,
        '--m-schedule-desc-fs': css.m_scheduleDescFS,
      }

      return { ...desktop, ...mobile }
    },
    cardType() {
      return parseInt(this.type) || 1
    }
  }
}
</script>

<style scoped>
  section[name=detail] p.title {
    font-family: var(--schedule-title-ff);
    font-size: var(--schedule-title-fs);
    color: var(--schedule-title-c);
  }

  section[name=detail] p.desc {
    font-family: var(--schedule-desc-ff);
    font-size: var(--schedule-desc-fs);
    color: var(--schedule-desc-c);
    margin-top: 0.313rem;
  }
</style>

<style mobile scoped>
  .mobile section[name=detail] {
    margin-top: 0.938rem !important;
  }

  .mobile section[name=detail] p.title {
    font-size: var(--m-schedule-title-fs);
  }

  .mobile section[name=detail] p.desc {
    font-size: var(--m-schedule-desc-fs);
    margin-top: 0.313rem;
  }
</style>