<template>
  <div
    class="notes-container"
    :class="{
      'p-5': isDesktop,
      'p-2-5': isMobile
    }"
    :style="{ backgroundColor: backgroundColor }"
  >
    <BorderedText :borderColor="borderColor" wide>
      <p class="py-2" :style="{ color: fColor, fontFamily: fFamily, fontSize: fSize}" v-html="text"></p>
    </BorderedText>
  </div>
</template>

<script>
export default {
  props: ["text", "bgColor", "fontColor", "fontFamily", "fontSize", "borderColor"],
  data() {
    return {
      backgroundColor: this.bgColor || "#707070",
      fColor: this.fontColor || "#fff",
      fFamily: this.fontFamily || "poppinsMI",
      fSize: this.fontSize || "1rem"
    }
  }
}
</script>