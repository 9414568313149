<template>
  <div
    class="countdown-container p-5"
    :style="{
      backgroundColor,
      ...styles
    }"
  >
    <template v-if="time.day != null">
      <BorderedText
        wide 
        :borderColor="borderColor"
        :borderWidth="borderWidth"
        :m_boteCardMinHeight="css.m_boteCardMinHeight"
        :boteCardMinHeight="css.boteCardMinHeight"
        :boteCardClass="{ 'd-flex align-items-center': isMobile }"
      >
        <div class="d-flex justify-content-center w-100">
          <template v-if="!justMarried">
            <div
              class="time-wrapper"
              :class="{
                'minw-25 maxw-25': isDesktop,
                'pl-7-5': isMobile
              }"
            >
              <div class="label">
                <p>Hari</p>
              </div>
              <div class="number">
                <p>{{ time.day }}</p>
              </div>
            </div>
            <div
              class="time-wrapper"
              :class="{
                'minw-25 maxw-25 px-25': isDesktop,
                'px-7-5': isMobile
              }"
            >
              <div class="label">
                <p>Jam</p>
              </div>
              <div class="number">
                <p>{{ time.hour }}</p>
              </div>
            </div>
            <div
              class="time-wrapper"
              :class="{
                'minw-25 maxw-25 pr-25': isDesktop,
                'pr-7-5': isMobile
              }"
            >
              <div class="label">
                <p>Menit</p>
              </div>
              <div class="number">
                <p>{{ time.minute }}</p>
              </div>
            </div>
            <div
              class="time-wrapper"
              :class="{
                'minw-25 maxw-25': isDesktop,
                'pr-7-5': isMobile
              }"
            >
              <div class="label">
                <p>Detik</p>
              </div>
              <div class="number">
                <p>{{ time.second }}</p>
              </div>
            </div>
          </template>
          <template v-else>
            <img :src="`/icons/${iconDir}/${justMarriedIcon}`">
          </template>
        </div>
      </BorderedText>
    </template>
  </div>
</template>

<script>
export default {
  props: ["date", "bgColor", "labelFontColor", "labelFontFamily", "labelFontSize", "numberFontColor", "numberFontFamily", "numberFontSize", "borderColor", "borderWidth", "m_labelFontSize", "m_numberFontSize"],
  data() {
    return {
      iconDir: this.$baseComponents.icon.dir,
      backgroundColor: this.bgColor || "#707070",
      lFontColor: this.labelFontColor || "#fff",
      lFontFamily: this.labelFontFamily || "openSansR",
      lFontSize: this.labelFontSize || "1.125rem",
      nFontColor: this.numberFontColor || "#fff",
      nFontFamily: this.numberFontFamily || "antsvalley",
      nFontSize: this.numberFontSize || "5rem",
      time: {
        day: null,
        hour: null,
        minute: null,
        second: null,
        distance: 0
      },
      css: {
        boteCardStyles: "",
        ...this.$baseComponents.countDown
      }
    }
  },
  computed: {
    styles() {
      const desktop = {
        '--label-font-color': this.lFontColor,
        '--label-font-family': this.lFontFamily,
        '--label-font-size': this.lFontSize,
        '--number-font-color': this.nFontColor,
        '--number-font-family': this.nFontFamily,
        '--number-font-size': this.nFontSize,
      }

      const mobile = {
        '--m-label-font-size': this.m_labelFontSize,
        '--m-number-font-size': this.m_numberFontSize,
      }

      return { ...desktop, ...mobile }
    },
    justMarried() {
      if(this.time.day == 0 && this.time.hour == 0 && this.time.minute == 0 && this.time.second == 0) return true
      
      return false
    },
    justMarriedIcon() {
      return this.isDesktop ? "justmarried-desktop.png" : "justmarried-mobile.png"
    }
  },
  methods: {
    getCountDown() {
      let countDownDate = new Date(this.date).getTime()
      let now = new Date().getTime()

      this.distance = countDownDate - now

      if (this.distance > 0) {
        setTimeout(() => {
          this.time.day = Math.floor(this.distance / (1000 * 60 * 60 * 24))
          this.time.hour = Math.floor((this.distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          this.time.minute = Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60))
          this.time.second = Math.floor((this.distance % (1000 * 60)) / 1000)

          this.getCountDown()
        }, 1000)
      } else {
        this.time.day = this.time.hour = this.time.minute = this.time.second = 0
      }
    }
  },
  mounted() {
    this.getCountDown()
  }
}
</script>

<style scoped>
  .label p {
    color: var(--label-font-color);
    font-family: var(--label-font-family);
    font-size: var(--label-font-size);
  }

  .number p {
    color: var(--number-font-color);
    font-family: var(--number-font-family);
    font-size: var(--number-font-size);
  }
</style>

<style mobile scoped>
  .mobile .countdown-container {
    margin-left: -2.5rem;
    margin-right: -2.5rem;
    padding-top: 0.7rem !important;
    padding-bottom: 0.7rem !important;
  }

  .mobile .label p {
    font-size: var(--m-label-font-size);
    margin-bottom: 0.938rem;
  }

  .mobile .number p {
    font-size: var(--m-number-font-size);
  }

  .mobile .number {
    width: 3.125rem;
  }
</style>