<template>
  <div id="time-decoration" :style="styles">
    <div name="bottom-center" v-if="decor.bottomCenter">
      <img :src="`${iconDir}/bottom-center.png`" class="decoration" alt="bottom-center-decoration" data-aos="zoom-in">
    </div>

    <div name="bottom-left" v-if="decor.bottomLeft">
      <img :src="`${iconDir}/bottom-left.png`" class="decoration" alt="bottom-left-decoration" data-aos="fade-right">
    </div>
    <div name="bottom-right" v-if="decor.bottomRight">
      <img :src="`${iconDir}/bottom-right.png`" class="decoration" alt="bottom-right-decoration" data-aos="fade-left">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    decor() {
      let decors = this.$decorations.time

      if(this.isMobile) decors = this.$checkMobileDecoration(decors)
      
      return decors
    },
    iconDir() {
      return `/decorations/${this.$baseComponents.icon.dir}/time/${this.device}`
    },
    styles() {
      const css = this.$decorations.time

      const desktop = {
        '--bottom-left-bottom': css.bottomLeft_bottom,
        '--bottom-left-left': css.bottomLeft_left,
        '--bottom-left-width': css.bottomLeft_width,
        '--bottom-left-z-index': css.bottomLeft_zIndex,

        '--bottom-right-bottom': css.bottomRight_bottom,
        '--bottom-right-right': css.bottomRight_right,
        '--bottom-right-width': css.bottomRight_width,
        '--bottom-right-z-index': css.bottomRight_zIndex
      }

      const mobile = {
        '--m-bottom-center-bottom': css.m_bottomCenter_bottom,
        '--m-bottom-center-width': css.m_bottomCenter_width,
        '--m-bottom-center-z-index': css.m_bottomCenter_zIndex
      }

      return { ...mobile, ...desktop }
    }
  }
}
</script>

<style scoped>
  [name=bottom-left] img {
    bottom: var(--bottom-left-bottom);
    left: var(--bottom-left-left);
    width: var(--bottom-left-width);
    z-index: var(--bottom-left-z-index);
  }

  [name=bottom-right] img {
    bottom: var(--bottom-right-bottom);
    right: var(--bottom-right-right);
    width: var(--bottom-right-width);
    z-index: var(--bottom-right-z-index);
  }
</style>

<style mobile scoped>
  .mobile [name=bottom-center] img {
    bottom: var(--m-bottom-center-bottom);
    width: var(--m-bottom-center-width);
    z-index: var(--m-bottom-center-z-index);
    margin: auto;
  }
</style>