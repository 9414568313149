<template>
  <div
    id="gift-section"
    class="d-flex align-items-center justify-content-center mt-18"
  >
    <div name="content">
      <section name="title">
          <Title text="Kirim Hadiah" />
      </section>

      <section name="gift" class="mt-5-752">
        <ColorWrapper
          class="maxw-275"
          :class="{
            'px-37-5 pt-10 pb-8-752': isDesktop,
            'px-8 mx-3-752 pt-6 pb-5': isMobile
          }"
          :backgroundColor="css.bgColor"
        >
          <div name="payments" class="d-flex justify-content-around">
            <template v-for="(payment, index) in $weddingData.payments">
              <PaymentCard
                v-bind="payment" 
                :key="index"
                :class="{
                  'mr-5': $weddingData.payments.length > 1 && (index + 1) != payments.length
                }"
              />
            </template>
          </div>

          <p 
            class="details text-center mt-7-5"
            :style="{
              '--gift-detail-font-family': css.giftDetailFontFamily,
              '--gift-detail-font-size': css.giftDetailFontSize,
              '--gift-detail-font-color': css.giftDetailFontColor,
            }"
          >Terimakasih banyak atas hadiah yang dikirimkan kepada kami</p>
        </ColorWrapper>
      </section>
    </div>
  </div>
</template>

<script>
import PaymentCard from "@/components/sections/gift/PaymentCard"

export default {
  components: { PaymentCard },
  data() {
    return {
      css: {
        giftDetailFontFamily: "poppinsR",
        giftDetailFontSize: "1rem",
        giftDetailFontColor: "#161616",

        ...this.$sections.gift
      }
    }
  }
}
</script>

<style scoped>
  p.details {
    font-family: var(--gift-detail-font-family);
    font-size: var(--gift-detail-font-size);
    color: var(--gift-detail-font-color);
  }
</style>

<style mobile scoped>
  .mobile p.details {
    font-size: 0.75rem !important
  }
</style>