<template>
  <div
    id="cover-section"
    class="d-flex align-items-center justify-content-center position-relative"
    :style="{ minHeight: css.windowHeight + css.unit, ...styles }"
  >
    <div name="content" data-aos="zoom-in">
      <section
        name="bride-groom"
        class="d-flex justify-content-center"
      >
        <div
          name="name" 
          :style="{
            width: css.nameWidth,
            marginBottom: $guestData ? css.marginBottom : (publicNameMarginBottom || css.marginBottom)
          }"
        >
          <p
            name="bride"
            class="w-max"
            :style="{
              paddingLeft: isDesktop ? css.bridePaddingLeft : css.m_bridePaddingLeft,
              paddingRight: isDesktop ? css.bridePaddingRight : css.m_bridePaddingRight,
              marginLeft: isDesktop ? css.brideMarginLeft : css.m_brideMarginLeft,
              marginRight: isDesktop ? css.brideMarginRight : css.m_brideMarginRight
            }"
          >
            {{ $weddingData.brideNickName }}
          </p>
          <p
            name="and"
            class="text-center"
            :style="{
              marginTop: isDesktop ? css.andMarginTop : css.m_andMarginTop,
              marginBottom: isDesktop ? css.andMarginBottom : css.m_andMarginBottom, 
              marginLeft: isDesktop ? css.andMarginLeft : css.m_andMarginLeft, 
              marginRight: isDesktop ? css.andMarginRight : css.m_andMarginRight
            }"
          >
            &
          </p>
          <p
            name="groom"
            class="w-max ml-auto"
            :style="{
              paddingRight: isDesktop ? css.groomPaddingRight : css.m_groomPaddingRight, 
              paddingLeft: isDesktop ? css.groomPaddingLeft : css.m_groomPaddingLeft,
              marginLeft: isDesktop ? css.groomMarginLeft : css.m_groomMarginLeft,
              marginRight: isDesktop ? css.groomMarginRight : css.m_groomMarginRight
            }"
          >
            {{ $weddingData.groomNickName }}
          </p>
        </div>
      </section>

      <section
        v-if="$guestData"
        name="dear"
        class="mt-15-252"
      >
        <p name="guest-name" class="text-center">Kepada Yth: {{ $guestData.name }}</p>
      </section>

      <section
        name="open" 
        class="d-flex justify-content-center"
        :style="{ marginTop: css.openBtnMarginTop }"
      >
        <Button icon="email-closed.svg" text="Buka Undangan" @click.native="$buttonClick(() => $emit('open'))"/>
      </section>
    </div>

    <div name="decorations">
      <CoverDecoration />
    </div>
  </div>
</template>

<script>
import CoverDecoration from "@/components/sections/cover/CoverDecoration"

export default {
  components: { CoverDecoration },
  data() {
    return {
      css: {
        unit: "px",
        nameWidth: null,
        nickPadding: null,
        windowHeight: window.innerHeight,
        
        //from database
        brGrFontFamily: "antsvalley",
        brGrFontSize: "4.375rem",
        brGrFontColor: "#161616",
        andFontFamily: "antsvalley",
        andFontSize: "5.625rem",
        andFontColor: "#161616",
        andMarginTop: "-3rem",
        andMarginBottom: "-2rem",
        andMarginLeft: "0",
        andMarginRight: "0",
        dearFontFamily: "poppinsR",
        dearFontSize: "1.25rem",
        dearColor: "#707070",
        openBtnMarginTop: "1.25rem",
        nameMarginBottom: "0",

        ...this.$sections.cover
      }
    }
  },
  computed: {
    styles() {
      const { css } = this
      const { cover } = this.$sections

      let desktop = {
        '--br-gr-font-family': css.brGrFontFamily,
        '--br-gr-font-size': css.brGrFontSize,
        '--br-gr-font-color': css.brGrFontColor,

        '--and-font-family': css.andFontFamily,
        '--and-font-size': css.andFontSize,
        '--and-font-color': css.andFontColor,

        '--dear-font-family': css.dearFontFamily,
        '--dear-font-size': css.dearFontSize,
        '--dear-color': css.dearColor,
      }

      const mobile = {
        '--m-br-gr-font-size': css.m_brGrFontSize,
        '--m-and-font-size': css.m_andFontSize,
        '--m-dear-font-size': css.m_dearFontSize,
        '--m-dear-font-color': css.m_dearFontColor,
      }

      return { ...desktop, ...mobile }
    },
    publicNameMarginBottom() {
      return this.isDesktop ? this.css.nameMarginBottomOnPublic : this.css.m_nameMarginBottomOnPublic
    }
  },
  methods: {
    __init() {
      this.getNameCss()
    },
    getNameCss() {
      const brideWidth = document.querySelector("p[name=bride]").clientWidth
      const groomWidth = document.querySelector("p[name=groom]").clientWidth
      const nameWidth = brideWidth + groomWidth
      const middleWidth = nameWidth / 2
      const unit = this.css.unit

      this.css.nameWidth = `${nameWidth}${unit}`
      // this.css.bridePaddingLeft = this.css.groomPaddingRight = `${brideWidth > middleWidth ? brideWidth - middleWidth : middleWidth - brideWidth}${unit}`
    }
  },
}
</script>

<style scoped>
  p[name=bride], p[name=groom] {
    font-family: var(--br-gr-font-family);
    font-size: var(--br-gr-font-size);
    color: var(--br-gr-font-color);
  }

  p[name=and] {
    font-family: var(--and-font-family);
    font-size: var(--and-font-size);
    color: var(--and-font-color);
    background: var(--and-font-color);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  section[name=dear] p {
    font-family: var(--dear-font-family);
    font-size: var(--dear-font-size);
    color: var(--dear-color);
  }
</style>

<style mobile scoped>
  .mobile p[name=bride], .mobile p[name=groom] {
    font-size: var(--m-br-gr-font-size);
  }

  .mobile p[name=and] {
    font-size: var(--m-and-font-size);
  }

  .mobile section[name=dear] p {
    font-size: var(--m-dear-font-size);
    color: var(--m-dear-font-color);
  }
</style>