<template>
  <div
    id="notes-section"
    class="d-flex align-items-center justify-content-center mt-13"
  >
    <div name="content" class="w-100">
      <Notes :borderColor="css.borderColor" :text="$weddingData.notes"/>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      css: {
        ...this.$baseComponents.notes
      }
    }
  }
}
</script>